<template>
  <v-card
    flat
    style="border: 1px solid #c3c3c3; height: 100%"
    class="d-flex flex-column flex-fill"
  >
    <div>
      <v-img
        :src="product.image[0]"
        :lazy-src="product.image[0]"
        aspect-ratio="1"
        class="pa-2 text-end"
      >
        <v-btn fab x-small :disabled="product.stok === 0" @click="addToCart()"
          ><v-icon>mdi-cart</v-icon></v-btn
        >
      </v-img>
    </div>
    <div class="pa-4 text-center">
      <div class="text-uppercase font-weight-bold">
        {{ product.plant }}
      </div>
      <div
        class="success--text d-flex align-center font-weight-black justify-center title"
      >
        $ {{ product.price }}
      </div>

      <div class="caption">Stock : {{ product.stok }}</div>
    </div>
    <v-spacer></v-spacer>
    <div class="px-1 pb-1 d-flex">
      <!-- <v-btn class="mr-2 px-0" depressed @click="shareProduct(product)"
        ><v-icon>mdi-share-variant</v-icon></v-btn
      > -->
      <v-btn
        :disabled="product.stok === 0"
        depressed
        color="success"
        class="flex-grow-1"
        @click="viewProduct()"
        >VIEW</v-btn
      >
    </div>

    <v-overlay v-if="product.stok === 0" absolute color="black">
      <div class="title font-weight-black red--text accent pa-2 rounded-lg">
        SOLD OUT
      </div>
    </v-overlay>
  </v-card>
</template>

<script>
import store from "@/store";

export default {
  props: {
    product: Object,
  },
  methods: {
    async addToCart() {
      const cart = await store.getters.g_cart;
      const product = { ...this.product };

      if (cart === null) {
        product.qty = 1;
        this.setCart([product]);
      } else {
        const NEWDATA = [...cart];
        const productExist = NEWDATA.filter((r) => r.id === this.product.id);

        if (productExist.length > 0) {
          const totalQty = productExist.map((r) => r.qty);
          const totalStock = productExist.map((r) => r.stok);
          if (totalQty < totalStock) {
            productExist[0].qty += 1;
            this.setCart(NEWDATA);
          } else {
            store.commit("CALL_SYSTEMBAR", {
              msg: "Product already to cart",
              color: "error",
            });
          }
        } else {
          product.qty = 1;
          NEWDATA.push(product);
          this.setCart(NEWDATA);
        }
      }
    },
    setCart(data) {
      store.commit("SET_CART", data);
      store.commit("CALL_SYSTEMBAR", {
        msg: "Success add product to cart",
        color: "success",
      });
    },
    viewProduct() {
      this.$emit("viewProduct");
    },
    async shareProduct(product) {
      console.log(product);
      try {
        // await navigator.clipboard.writeText(urlLink);
        store.commit("CALL_SYSTEMBAR", {
          msg: "Success Copy Link",
          color: "success",
        });
      } catch ($e) {
        alert("Cannot copy");
      }
    },
  },
};
</script>
