<template>
  <div>
    <v-img :src="cover" height="250" aspect-ratio="16/9">
      <div class="d-flex justify-center align-center backdrop-grad">
        <div class="display-3 white--text">CATALOG</div>
      </div>
    </v-img>

    <v-container class="my-6">
      <v-row>
        <v-col cols="12">
          <v-slide-group multiple show-arrows>
            <v-slide-item
              v-for="(cat, i) in categories"
              :key="i"
              v-slot="{ active, toggle }"
            >
              <v-btn
                :input-value="active"
                :class="query.category == cat.key && 'active-btn-menu'"
                class="btn-menu"
                text
                @click="
                  () => {
                    toggle;
                    setCategory(cat);
                  }
                "
                >{{ cat.key }}</v-btn
              >
            </v-slide-item>
          </v-slide-group>
        </v-col>
        <v-col cols="12">
          <div class="d-flex">
            <v-text-field
              v-model="query.search"
              placeholder="Search product"
              outlined
              hide-details
              dense
              class="mr-2"
              @keyup.enter="searchProduct"
            ></v-text-field>
            <v-btn @click="searchProduct" color="primary"
              ><v-icon>mdi-magnify</v-icon></v-btn
            >
          </div>
        </v-col>
        <v-col
          v-for="(product, i) in g_products"
          :key="i"
          cols="12"
          sm="4"
          md="3"
          align-self="stretch"
        >
          <Product :product="product" @viewProduct="viewProduct(product)" />
        </v-col>
        <v-col v-if="query.last_page !== 1" cols="12">
          <v-pagination
            v-model="query.page"
            :length="query.last_page"
            @input="paginate"
          ></v-pagination>
        </v-col>
      </v-row>

      <v-dialog
        v-model="modalProduct"
        :fullscreen="isMobile()"
        persistent
        width="800"
      >
        <v-card>
          <v-card-title>
            <v-spacer></v-spacer>
            <v-icon @click="viewProduct()" color="success"
              >mdi-close-thick</v-icon
            >
          </v-card-title>
          <v-card-text>
            <ProductDetail :product="productDetail" />
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Product from "@/components/Product";
import ProductDetail from "@/components/ProductDetail";
// import { productList, categoryList } from "@/dummy_data.js";

export default {
  components: {
    Product,
    ProductDetail,
  },
  data() {
    return {
      cover: "",
      categories: [],
      query: {
        search: "",
        category: "",
        limit: 12,
        page: 1,
        last_page: 1,
        init: false,
      },
      total: 0,
      nextPage: null,
      productDetail: null,
      modalProduct: false,
    };
  },
  computed: {
    ...mapGetters(["g_products", "g_categories", "g_profile"]),
  },
  async mounted() {
    this.cover = this.g_profile.home_cover;
    await this.$store.dispatch("getCategories");
    const res = await this.$store.dispatch("getCatalogs", this.query);
    this.nextPage = res.next_page_url;
    this.query.last_page = res.last_page;

    this.categories = [
      {
        key: "ALL",
      },
      ...this.g_categories,
    ];

    this.query.category = "ALL";
    if (this.$route.query.category)
      this.query.category = this.$route.query.category;
  },
  methods: {
    paginate(e) {
      this.query.page = e;
      this.getCatalogs();
    },
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    async searchProduct() {
      this.query.page = 1;
      this.getCatalogs();
    },
    setCategory(cat) {
      this.query.page = 1;
      this.query.category = cat.key;
      this.getCatalogs();
    },
    async getCatalogs() {
      const res = await this.$store.dispatch("getCatalogs", this.query);
      this.nextPage = res.next_page_url;
      this.query.last_page = res.last_page;
    },
    viewProduct(product) {
      if (product) {
        this.productDetail = product;
        this.modalProduct = true;
      } else {
        this.productDetail = null;
        this.modalProduct = false;
      }
    },
  },
};
</script>
