<template>
  <v-row v-if="product" class="product-detail">
    <v-col cols="12" sm="4">
      <div class="prod-image">
        <v-img
          :src="selectedProduct"
          :lazy-src="selectedProduct"
          aspect-ratio="1"
          @click="viewImage()"
        ></v-img>
      </div>
      <v-row class="mt-2" align="center" justify="center" dense>
        <v-col
          v-for="(img, i) in product.image"
          :key="i"
          cols="3"
          style="cursor: pointer"
          @click="setProduct(img)"
        >
          <v-card class="rounded">
            <v-img
              :src="img"
              :lazy-src="img"
              class="rounded"
              aspect-ratio="1"
            />
          </v-card>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" sm="8">
      <div class="display-1 font-weight-bold mb-2">{{ product.plant }}</div>
      <div class="display-3 font-weight-bold success--text mb-2">
        $ {{ product.price }}
      </div>
      <div class="body-1 mb-4">Stock: {{ product.stok }}</div>
      <v-row>
        <v-col cols="12" sm="6" align-self="center">
          <div class="body-2 font-weight-black">
            Shipping calculated at checkout
          </div>
        </v-col>
        <v-col cols="12" sm="6" align-self="center">
          <v-text-field
            v-model="qty"
            :max="product.stok"
            label="Quantity"
            type="number"
            min="1"
            outlined
            hide-details
            dense
            @change="setQty(product)"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-divider class="my-6"></v-divider>
      <div class="body-1 mb-6">
        <span
          v-html="product.description ? product.description : 'No description'"
        ></span>
      </div>
      <v-row>
        <v-col cols="6">
          <v-btn color="success" depressed block @click="addToCart('buy')"
            >BUY</v-btn
          >
        </v-col>
        <v-col cols="6">
          <v-btn outlined block @click="addToCart()">Add to Cart</v-btn>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import store from "@/store";

export default {
  props: {
    product: Object,
  },
  data() {
    return {
      qty: 1,
      selectedProduct: "",
    };
  },
  watch: {
    product(newVal) {
      if (newVal && newVal.image) {
        this.selectedProduct = newVal.image[0];
      }
    },
  },
  mounted() {
    if (this.product) this.selectedProduct = this.product.image[0];
  },
  destroyed() {
    this.selectedProduct = "";
  },
  methods: {
    viewImage() {
      if (this.selectedProduct) {
        window.open(this.selectedProduct, "_blank");
      }
    },
    setProduct(product) {
      this.selectedProduct = product;
    },
    setQty(product) {
      if (this.qty > product.stok) {
        this.qty = product.stok;
      }
    },
    async addToCart(buy) {
      const cart = store.getters.g_cart;
      const product = { ...this.product };

      if (cart === null) {
        product.qty = this.qty;
        this.setCart([product]);
        if (buy) this.$router.push("/cart");
      } else {
        const NEWDATA = [...cart];
        const productExist = NEWDATA.filter((r) => r.id === this.product.id);

        if (productExist.length > 0) {
          const totalQty = productExist.map((r) => r.qty);
          const totalStock = productExist.map((r) => r.stok);
          if (totalQty < totalStock) {
            productExist[0].qty += 1;
            this.setCart(NEWDATA);
            if (buy) this.$router.push("/cart");
          } else {
            if (buy) this.$router.push("/cart");
            else
              store.commit("CALL_SNACKBAR", {
                msg: "Product already to cart",
                color: "error",
              });
          }
        } else {
          product.qty = this.qty;
          NEWDATA.push(product);
          this.setCart(NEWDATA);
          if (buy) this.$router.push("/cart");
        }
      }
    },
    setCart(data) {
      store.commit("SET_CART", data);
      store.commit("CALL_SNACKBAR", {
        msg: "Success add product to cart",
        color: "success",
      });
    },
  },
};
</script>

<style lang="scss">
.product-detail {
  .prod-image {
    .v-image {
      border: 1px solid grey;
      border-radius: 8px;
    }
  }
}
</style>
